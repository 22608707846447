import * as request from '@/utils/request'

export default {
    /**
     * @description 获取新绩效-红人管理列表
     * @returns promise
     */
    getPaKpiKolList(data) {
        return request.post('/paKpiKol/list', data)
    },
    /**
     * @description 获取新绩效-添加红人
     * @returns promise
     */
    savePaKpiKolList(data) {
        return request.post('/paKpiKol/add', data)
    },
    /**
     * @description 获取新绩效-删除红人
     * @returns promise
     */
    deletePaKpiKolList(data) {
        return request.post('/paKpiKol/delete', data)
    },
    /**
     * @description 获取新绩效-项目组列表
     * @returns promise
     */
    getPaKpiDeptList(data) {
        return request.post('/paKpiDept/list', data)
    },
    /**
     * @description 获取新绩效-项目组新增
     * @returns promise
     */
    addPaKpiDeptData(data) {
        return request.post('/paKpiDept/add', data)
    },

    /**
     * @description 获取新绩效-项目组删除
     * @returns promise
     */
    deletePaKpiDeptData(data) {
        return request.post('/paKpiDept/delete', data)
    },
    /**
     * @description 获取新绩效-红人绩效目标
     * @returns promise
     */
    getSetTargetList(data) {
        return request.post('/paKpiKol/set_target_list', data)
    },
    /**
     * @description 获取新绩效-红人绩效目标
     * @returns promise
     */
    refreshSetTargetList(data) {
        return request.post('/paKpiKol/refresh_income_profit', data)
    },

    /**
     * @description 设置新绩效-红人绩效-前两个月数据
     * @returns promise
     */
    EditTargetData(data) {
        return request.post('/paKpiKol/edit', data)
    },
    /**
     * @description 设置新绩效-红人绩效-项目组目标
     * @returns promise
     */
    saveTargetData(data) {
        return request.post('/paKpiTarget/save', data)
    },
    /**
     * @description 设置新绩效-红人绩效-发布绩效
     * @returns promise
     */
    releaseTargetData(data) {
        return request.post('/paKpiTarget/release', data)
    },
    /**
     * @description 设置新绩效-项目组目标列表
     * @returns promise
     */
    getprizeInfoGroup(data) {
        return request.post('/paKpiPrize/prize_info_group', data)
    },
    /**
     * @description 设置新绩效-项目组目标列表
     * @returns promise
     */
    getPaKpiRecordList(data) {
        return request.post('/paKpiRecord/list', data)
    },
    /**
     * @description 设置新绩效-项目组奖金保存
     * @returns promise
     */
    savePrizeInfoGroup(data) {
        return request.post('/paKpiPrize/save', data)
    },
    /**
     * @description 设置新绩效-项目组奖金
     * @returns promise
     */
    releasePrizeInfoGroup(data) {
        return request.post('/paKpiPrize/release', data)
    },

    /**
     * @description 设置新绩效-项目组目标列表
     * @returns promise
     */
    getUnionList(data) {
        return request.post('/paKpiRecord/listUnion', data)
    },
    /**
     * @description 设置新绩效-修改
     * @returns promise
     */
    editUnionList(data) {
        return request.post('/paKpiRecord/save', data)
    },
    /**
     * @description 设置新绩效-修改
     * @returns promise
     */
    syncUnionList(data) {
        return request.post('/paKpiRecord/refresh_kol_data', data)
    },
    //
    /**
     * @description 设置新绩效-项目组目标列表
     * @returns promise
     */
    getPrizeGmList(data) {
        return request.post('/paKpiPrize/prize_info_gm', data)
    },
    /**
     * @description 设置新绩效-项目组目标列表
     * @returns promise
     */
    getVpPrizeGmList(data) {
        return request.post('/paKpiPrize/prize_info_vp', data)
    },
    /**
     * @description 获取奖金接口
     * @returns promise
     */
    getShareList(data) {
        return request.post('/paKpiPrize/share_list_group', data)
    },
    /**
     * @description 下拉筛选
     * @returns promise
     */
    getShareOptionList(data) {
        return request.post('/paKpiPrize/list_option', data)
    },
    /**
     * @description 下拉筛选
     * @returns promise
     */
    getShareStatusOptionList(data) {
        return request.post('/paKpiPrize/review_state_option', data)
    },
    /**
     * @description 添加成员
     * @returns promise
     */
    savePaKpiPrizeItem(data) {
        return request.post('/paKpiPrizeItem/save', data)
    },
    /**
     * @description 奖金详情
     * @returns promise
     */
    getPaKpiPrizeInfo(data) {
        return request.post('/paKpiPrize/get_info', data)
    },
    /**
     * @description 确认奖金分配
     * @returns promise
     */
    confirmPaKpiPrize(data) {
        return request.post('/paKpiPrize/confirm_prize', data)
    },
    /**
     * @description 确认奖金分配
     * @returns promise
     */
    revokePaKpiPrize(data) {
        return request.post('/paKpiPrize/revoke_confirm_prize', data)
    },
    /**
     * @description 确认奖金分配
     * @returns promise
     */
   saveSetShare(data) {
        return request.post('/paKpiPrizeItem/set_share_ratio', data)
    },
    getMcnDeptSettingOptions(data) {
        return request.post('/paKpiDept/option', data)
    },
    initDeptBonus(data) {
        return request.post('/paKpiPrize/init_member', data)
    },
    /**
     * @description 奖金列表导出接口
     * @returns promise
     */
    getShareExportList(data) {
        return request.postBlob('/paKpiPrize/export', data)
    },
    confirmBonus(data) {
        return request.post('/paKpiTarget/confirm', data)
    },
//
}