import Vue from 'vue'
import ElementUI from 'element-ui'
import UmyUi from 'umy-ui' //大数据表格
import 'umy-ui/lib/theme-chalk/index.css' // 引入样式
import 'element-ui/lib/theme-chalk/display.css'
import '@/assets/index.css'
import '@/assets/styles/color.scss'
import '@/assets/festival/index.scss'
import '@/assets/icons'
import App from '@/layouts/App.vue'
import router from '@/router/router'
// 自动检测更新
import '@/utils/sys/auto-update.js'
// import '@/assets/progress.css'
// import '@/utils/sys/network.js'
// 默认会找store目录下面的index.js文件
import store from '@/store'
import api from '@/api'
import {handleTree, parseTime, resetForm, selectDictLabel} from '@/utils/ruoyi'
import * as utils from '@/utils/utils'
import Pagination from '@/components/Pagination'

import '@/style/button/ripple.js'
import '@/style/button/ripple.css'
import VueClipboard from 'vue-clipboard2'
import {hasAuthority} from '@/utils/hasAuthority'
//Message实例（注册全局webSocket服务）
import Message from '@/ws-serve/message'

import JsonExcel from 'vue-json-excel'
import {RegisterFilter} from '@/utils/filter'
import {RegisterDirectives} from '@/utils/directives'

Vue.prototype.$globalWs = new Message() //登录页面的时候不需要连接
Vue.component('downloadExcel', JsonExcel)

Vue.use(VueClipboard)
Vue.prototype.resetForm = resetForm
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.parseTime = parseTime
Vue.prototype.handleTree = handleTree
Vue.prototype.hasAuthority = hasAuthority

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$utils = utils
// eslint-disable-next-line
Vue.prototype.$debugLogger = function(...args) {
  // console.log('mode', process.env.VUE_APP_DEBUG)
  if (process.env.VUE_APP_DEBUG == 1) {
    console.log(...args)
  }
}
Vue.use(UmyUi)

// Vue.use(ElementUI)
//element-ui dialog全局点击遮罩层不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false
//element-ui 消息提示全局修改
Vue.prototype.$message = function(msg) {
  ElementUI.Message({ ...msg, duration: 1000, showClose: true })
}
// 默认尺寸
Vue.use(ElementUI, {
  // size: Cookies.get('size') || 'medium' // set element-ui default size
  size: 'mini' // set element-ui default size
})

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('MPagination', resolve => require(['@/components/common/MPagination'], resolve))
Vue.component('MTable', resolve => require(['@/components/common/MTable'], resolve))
Vue.component('ApeMenu', resolve => require(['@/components/ApeMenu'], resolve))//页面内菜单
Vue.component('ArtistNickname', resolve => require(['@/pages/artist/ArtistNickname'], resolve))
Vue.component('DeptNameTag', resolve => require(['@/pages/departments/components/DeptNameTag'], resolve))
Vue.component('Tips', resolve => require(['@/components/Tips'], resolve))
Vue.component('ImageShare', resolve => require(['@/components/ImageShare'], resolve))
Vue.component('DeptCascader', resolve => require(['@/pages/departments/DeptCascader'], resolve))
Vue.component('DeptCascaderScope', resolve => require(['@/pages/departments/DeptCascaderScope'], resolve))//带权限的部门选择器
Vue.component('DelayNotify', resolve => require(['@/pages/business/DelayNotify'], resolve))//带权限的部门选择器
Vue.component('CompanySelect', resolve => require(['@/components/CompanySelect'], resolve))//全公司选择器
Vue.component('InitLoading', resolve => require(['@/components/InitLoading.vue'], resolve))//loaing


// Vue.component('PageHeaderLayout', resolve => require(['@/layouts/PageHeaderLayout'], resolve));
Vue.prototype.msgSuccess = function(msg) {
  this.$message({ showClose: true, message: msg, type: 'success' })
}

Vue.prototype.msgError = function(msg) {
  this.$message({ showClose: true, message: msg, type: 'error' })
}
Vue.prototype.msgWarn = function(msg) {
  this.$message({ showClose: true, message: msg, type: 'warning' })
}
Vue.prototype.msgInfo = function(msg) {
  this.$message.info(msg)
}
//路由跳转
Vue.prototype.routerJump = function(pageName, query, params, target) {
  if (target === undefined)
    target = '_self'

  if (target === '_self') {
    this.$router.push({
      name: pageName,
      query: query
    })
  } else {
    const { href } = this.$router.resolve({
      name: pageName,
      query: query,
      params: params
    })
    window.open(href, '_blank')
  }
}

//
// Sentry.init({
//   Vue,
//   dsn: "http://cb77f76862c04048b8d505c46bc7664c@sentry.mulaitek.com/6",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });


//注册全局 权限判断指令
// Vue.directive('hasAuthority',
//   function(el, binding) {
//     window.onload = function() {
//       if (store?.state?.userPermissions?.length !== 0) {
//         let isShow = store.state.userPermissions.indexOf(binding.value) > -1
//         console.log(isShow)
//         if (!isShow) {
//           let first = el.firstElementChild
//           while (first) {
//             first.remove()
//             first = el.firstElementChild
//           }
//           console.log('?',el.parentNode)
//           el.parentNode && el.parentNode.removeChild(el)
//         }
//       }
//     }
//   })
Vue.directive('hasAuthority', {
  inserted: (el, binding) => {
    const checkAuthority = () => {
      // 1. 获取最新权限数据
      const permissions = store?.state?.userPermissions || []
      const permissionsLoaded = store?.state.permissionsLoaded || false

      if (!permissionsLoaded) {
        // 权限数据尚未加载，延迟检查
        setTimeout(checkAuthority, 100);
        return;
      }
      // 2. 增强类型检查
      if (typeof binding.value !== 'string') {
        console.warn('[hasAuthority] 指令需要字符串类型参数')
        return
      }
      if (permissions && permissions.length > 0) {
        // 3. 权限匹配逻辑
        const hasPermission = permissions.includes(String(binding.value))
        // 4. 安全DOM操作
        if (!hasPermission) {
          // 保留原始display值用于可能的恢复
          el.dataset.originalDisplay = el.style.display
          el.style.display = 'none'

          // 同时隐藏子元素
          Array.from(el.children).forEach(child => {
            child.style.display = 'none'
          })
        }
      }
    }

    // 5. 响应式更新机制
    const unwatch = store.watch(
        state => state.userPermissions,
        checkAuthority,
        {deep: true}
    )

    // 6. 清理函数注册
    const cleanup = () => {
      unwatch?.()
      delete el._authorityCleanup
    }

    // 7. 注册卸载回调
    el._authorityCleanup = cleanup
    checkAuthority()
  },

  unbind: (el) => {
    // 8. 恢复原始显示状态
    if (el.dataset.originalDisplay) {
      el.style.display = el.dataset.originalDisplay
      Array.from(el.children).forEach(child => {
        child.style.display = ''
      })
    }

    // 执行清理
    el._authorityCleanup?.()
  }
})
// 注册Filter
new RegisterFilter(Vue)
new RegisterDirectives(Vue)

Vue.directive('scroll', {
  inserted: function(el, binding) {
    let f = function(evt) {
      // console.log(binding)
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
  data: {
    ws: null
  }

}).$mount('#app')
