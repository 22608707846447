// 跟级别的Getters
const getters = {
    menuList: state => state.menuList,
    isCollapse: state => state.isCollapse,
    routePath: state => state.routePath,
    userInfo: state => state.userInfo,
    roles: state => state.user.roles,
    permissions: state => state.user.permissions,
    userPermissions: state => state.userPermissions,
    permissionsLoaded: state => state.permissionsLoaded,
    userDptInfo: state => state.userDptInfo,
    respErrMsgBoxMark: state => state.responseErrorMessageBoxMark,
    buttonType: state => state.buttonType,
    loadingStatus: state => state.loadingStatus,
    notifyTotal: state => state.notifyTotal,
    visitedViews: state => state.tagsView.visitedViews,
    cachedViews: state => state.tagsView.cachedViews,
    needTagsView: state => state.settings.tagsView,
}

export default getters
