import Vue from 'vue'
import Router from 'vue-router'
import $vuex from '@/store'
import api from '@/api'
import login from './login'
import employee from './employee'
import ws from './ws'
import home from './home'
import index from './index'
import migration from './migration'
import dashboard from './dashboard'
import menu from './menu'
import permissions from './permissions'
import roles from './roles'
import user from './user'
import setting from './setting'
import user_setting from './user_setting'
import system from './system'
import category from './category'
import article from './article'
import statistics from './statistics'
import attachment from './attachment'
import sysLog from './sys_log'
import oper_log from './oper_log'
import artist from './artist'
import trend from './trend'
import trend_data from './trend_data'
import revenue from './revenue'
import departments from './departments'
import mcnGroups from './mcn_groups'
import platform from './platform'
import data from './data'
import notify from './notify'
import performance from './performance'
import sign_info from './sign_info'
import business_data from './business_data'
import live_data from './live_data'
import shop_data from './shop_data'
import artist_schedule from './artist_schedule'
import case_record from './case_record'
import performance_trend from './performance_trend'
import download_file from './download_file'
import ding_talk_event from './ding_talk_event'
import business from './business'
import s_menu from './s_menu'
import revenue_menu from './revenue_menu'
import network_ip from './network_ip'
import team from './team'
import team_mcn from './team_mcn'
import plc from './plc'
import plc_item from './plc_item'
import plc_detail from './plc_detail'
import plc_check from './plc_check'
import notice from './notice'
import lb_menu from './lb_menu'
import product_selection from './product_selection'
import product_selection_ack from './product_selection_ack'
import sys_notice from './sys_notice'
import Artist_Commission from './artist_commission'
import kol from './kol'
import oaManage from './oaManage'
import oaTemplate from './oaTemplate'
import inquiries from './inquiries'
import sys_post from './sys_post'
import sys_dept from './sys_dept'
import myNav from './myNav'
import Settlement from './settlement'
import kolModel from './kolModel'
import commerce from '@/router/commerce'
import analysis_basic from '@/router/analysis_basic'
import demo from './demo'
import balance from './balance'
import beisen from './beisen'
import logistics from './logistics'
import product_specimen from './product_specimen'
import logistic_subscribe from './logistic_subscribe'
import {staticPermissions} from '@/utils/staticPermissions'
import logistic_subscribe_message from './logistic_subscribe_message'
import role_apply_record from './role_apply_record'
import workbench from './workbench'
import warehouse from './warehouse'
import warehouse_location from '@/router/warehouse_location'
import product_qty from '@/router/product_qty'
import product_qty_record from '@/router/product_qty_record'
import approval from './approval'
import custom_barcode from '@/router/custom_barcode'
import express_take_over from '@/router/express_take_over'
import pa from '@/router/pa'
import enrollIndex from '@/router/enrollInquiries'
import mandateInquiry from '@/router/mandateInquiry'
import trade from '@/router/trade'
import emphasis_product from '@/router/emphasis_product'
import mcn_performance from '@/router/mcn_performance'
import performance_goal_achievement from '@/router/performance_goal_achievement'
import bonus_management from '@/router/bonus_management'

// import VueRouter from 'vue-router'
// // 解决vue报错vue-router.esm.js
// const routerPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch((error) => error)
// }

/* 在创建router实例对象之前，手动覆盖原型链的push来吞掉报错catch */
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) return originalPush.call(this, location, resolve, reject)
  return originalPush.call(this, location).catch((e) => {
    if (process.env.VUE_APP_DEBUG == 1)
      console.log('router err:', e)
  })
}

Vue.use(Router)

// 路由详情
const routes = [
  {
    path: '/',
    component: () => import('@/layouts/BasicLayout'),
    name: 'BasicLayout',
    meta: { title: '首页' },
    redirect: '/profile/workbench',
    children: [
      home, // 首页
      index, // 主页
      system, // 系统- monitor, // 系统监控
      employee,
      demo, // 示例
      migration, // 表迁移
      sys_dept, // 系统部门
      oper_log, // 业务日志
      menu, // 菜单
      permissions, // 权限
      roles, // 角色
      user, // 用户
      setting, // 基础设置
      user_setting,//用户设置
      network_ip, //网络IP
      category, // 分类管理
      article, // 文章管理
      statistics, // 统计管理
      attachment, // 附件管理
      sysLog, // 系统日志
      artist, // 红人名录
      trend, // 涨粉数据
      trend_data, // 涨粉数据
      revenue, // 营收数据
      departments, // 部门
      mcnGroups, // 分组
      platform, // 平台
      dashboard, // 仪表盘
      data, // 数据管理
      notify, // 消息通知
      performance, // 绩效管理
      pa, // 绩效管理
      performance_trend, // 涨粉绩效
      sign_info, // 签约信息
      business_data, // 电商数据
      live_data, // 直播数据
      shop_data, // 店铺数据
      artist_schedule, // 档期管理
      case_record, // 视频案例
      download_file, // 下载管理
      // upload_file,//上传管理
      business, //商务管理
      s_menu, //顶部菜单-档期
      revenue_menu, //顶部菜单-营收
      sys_notice,//顶部菜单-公告
      team, //团队详情
      plc, //刊例
      plc_detail, //刊例提报
      notice, //系统公告
      // lb, //直播
      lb_menu, //直播
      product_selection_ack, //直播
      product_selection, //选品
      Artist_Commission,//红人分成
      kol,//红人资源库
      oaManage,//oa审批管理
      oaTemplate,//oa模板管理
      inquiries,//询单
      enrollIndex,//询单
      mandateInquiry,//询单
      ws,
      sys_post,//岗位管理
      myNav,//我的导航
      Settlement,//项目结款
      kolModel,//KOL红人模型
      commerce,//电商绩效
      // laboCost,//人工成本
      analysis_basic,
      // business_support_team,
      // rebate,//返点费用
      // mcn_promotion,//MCN推广花费
      // live_funding,//直播投放金
      ding_talk_event,//钉钉事件通知
      team_mcn,//团队MCN
      balance,//账号余额
      beisen,//北森报表
      logistics,//快递管理
      product_specimen,//寄样管理
      logistic_subscribe,//物流信息订阅记录
      logistic_subscribe_message,//物流信息订阅记录
      role_apply_record,//角色申请
      workbench,//工作台
      approval,//申请类列表
      warehouse,//仓库
      warehouse_location,//库位
      product_qty,//库存查询
      product_qty_record,//进销存管理,
      custom_barcode, //条码管理
      express_take_over, //寄样快递库位
      trade, //订单数据（平台交易数据）
      emphasis_product, //重点大全案品
      mcn_performance,//新的绩效管理
      performance_goal_achievement,//绩效目标管理
      bonus_management,//绩效奖金分配
      {
        path: 'exception/404',
        name: 'Error404',
        component: () => import(/* webpackChunkName: "exception-404" */ '@/pages/exception/404')
      }
    ]
  },
  {
    path: '/',
    name: 'PageLayout',
    meta: { title: '页面' },
    component: () => import('@/layouts/PageLayout'),
    children: [
      plc_item, //刊例提报
      plc_check, //刊例-审核页面
    ]
  },
  {
    path: '/',
    name: 'PassportLayout',
    component: () => import('@/layouts/PassportLayout'),
    children: [
      login // 登录
    ]
  },
  // {
  //     path: '/',
  //     component: () => import('@/layouts/DemoLayout'),
  //     children: [
  //         ws, // WebSocket 通讯调试
  //     ]
  // },
  {
    path: '*',
    redirect: '/exception/404'
    // component: () => import('@/pages/exception/404')
  }
]

// 实例化
const router = new Router({
  base: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL : '/',
  mode: 'history',
  routes
})

// 路由钩子
router.beforeEach((to, from, next) => {
  ifLogin(to, next)
  // console.log($vuex.getters.userPermissions)
  //判断已经加载权限
  ifPermissions()
    .then(() => {
      // 加载完毕权限后，加载默认页面
      defaultToPage(to, from, next)
    })
    .catch((err) => {
      console.log('err', err)
      // next({ path: '/' })
    })
  // 侧边栏菜单折叠
  handleSiderMenu(to)
  //提交用户行为
  // commitPageChange(to.fullPath, from.fullPath).then(()=>{
  //   // console.log('commit page change !!!')
  //   // console.log('from',from.fullPath)
  // })
  //页面跳转
  next()
})

//提交用户行为
// let commitPageChange = async (toFullPath, fromFullPath) => {
//   try {
//     // $vuex.commit('handleRoutePath', to.path)
//     let data = await api.commitUserAction(toFullPath, fromFullPath)
//     if (data) {
//       // console.log('ok')
//       return data
//     }
//   }catch (e){
//     console.log('commitPageChange err')
//   }
//
// }

/**
 * @description 处理左侧菜单的选中
 * @param {*} to 当前路由
 */
let handleSiderMenu = (to) => {
  $vuex.commit('handleRoutePath', to.path)
}

/**
 * @param {*} to 当前路由
 * @param {*} next 回调函数
 */
let ifLogin = (to, next) => {
  // let userInfo = JSON.parse(localStorage.getItem('user_info'))
  let token = localStorage.getItem('HYPERF_SESSION_ID')
  // 判断是否是登录页
  let idx = to.path.indexOf('login') !== -1
  // 没有userInfo 且 不是login 页 => 登录页
  if (!token && !idx) {
    next(`/login?redirect=${to.fullPath}`)
  }
  // 有userinfo 且 是login 页 => 首页
  if (idx && token) {
    next('/')
  }
}
let ifPermissions = async () => {
  let token = localStorage.getItem('HYPERF_SESSION_ID')
  if (token) {
    if ($vuex.getters.menuList.length === 0) {
      let userMenuList = await api.getUserMenuList()
      await $vuex.commit('handleMenuList', userMenuList['list'])
    }

    if ($vuex.getters.userPermissions.length <= staticPermissions.length) {
      //若无权限，则加载权限
      let userPermissionsList = await api.getUserPermissionsList()
      await $vuex.commit('handleUserPermissions', userPermissionsList['list'])
      if (userPermissionsList && userPermissionsList['list']) {
        await $vuex.commit('handlePermissionsLoaded', true)
      }
      let userPermissionsDeptList = await api.getUserPermissionsDeptList()
      await $vuex.commit('handleUserDptInfo', userPermissionsDeptList['dpt_info'])
    }
    // else{
    //     console.log('已加载权限')
    // }
  }
}

//登录后默认调整的页面，在权限加载之后执行
let defaultToPage = (to, from, next) => {
  let fromPath = from.path
  let toPath = to.path
  // if (to.path === '/' || to.path === '/myNav') {
  //   // console.log('首页、home')
  //   // let userPermissions = $vuex.getters.userPermissions
  //   // if (userPermissions.indexOf('manage_dashboard_menu') !== -1) {
  //   //   toPath = '/dashboard'
  //   // } else if (userPermissions.indexOf('dashboard_trend') !== -1) {
  //   //   toPath = '/trend_data'
  //   // } else {
  //   //   toPath = '/trend_data'
  //   // }
  //
  //   // toPath = '/myNav'
  //
  //   // console.log('userPermissions', userPermissions)
  // }

  if (toPath !== to.path && fromPath !== toPath) {
    // console.log('toPath', toPath)
    next(toPath)
  }
}

export default router
