export default {
    path: 'bonus_management',
    name: 'bonus_management',
    component: () => import('@/pages/bonus_management/Performance'),
    meta: {title: '奖金分配'},
    children: [
        {
            path: 'objectives',
            name: 'bonus_objectives',
            component: () => import('@/pages/bonus_management/BonusIndex'),
            meta: {title: '奖金分配'},
        },
        {
            path: 'director',
            name: 'performance_bonus',
            component: () => import('@/pages/bonus_management/directorIndex.vue'),
            meta: {title: '总监绩效'},
        },
        {
            path: 'vp',
            name: 'case_record',
            component: () => import('@/pages/bonus_management/vpIndex.vue'),
            meta: {title: 'vp绩效'},
        },
        // {
        //     path: 'objectives',
        //     name: 'performance_goal_achievement',
        //     component: () => import('@/pages/mcn_performance_data/performance_goal_achievement.vue'),
        //     meta: { title: '绩效完成-项目组' },
        // },
    ]
}
