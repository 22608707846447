export default {
    path: 'goal_tracking',
    name: 'goal_tracking',
    component: () => import('@/pages/goal_tracking/Performance'),
    meta: { title: '绩效目标完成' },
    children: [
        {
            path: 'objectives',
            name: 'goal_tracking_project_team',
            component: () => import('@/pages/goal_tracking/project_team'),
            meta: { title: '绩效目标完成-项目组' },
        },
        {
            path: 'director',
            name: 'goal_tracking_director',
            component: () => import('@/pages/goal_tracking/directorIndex.vue'),
            meta: { title: '绩效目标完成-总监' },
        },
        {
            path: 'vp',
            name: 'goal_tracking_vp',
            component: () => import('@/pages/goal_tracking/vpIndex.vue'),
            meta: { title: 'v绩效目标完成-VP' },
        },
        // {
        //     path: 'bonus_target_allocation',
        //     name: 'bonus-target-allocation',
        //     component: () => import('@/pages/goal_tracking/BonusIndex.vue'),
        //     meta: { title: '绩效完成-项目组' },
        // },
    ]
}
