import * as request from '@/utils/request'

export default {
  /**
   * @description 获取【涨粉】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  getPaKolTrendQuarterData(data) {
    return request.post('/pa_kol_trend/get_quarter_data', data)
  },
  /**
   * 绩效考核的MCN中心下-我负责的部门、项目组
   * @param data
   * @returns {Promise}
   */
  getPaKolTrendAllowDeptList(data) {
    return request.post('/pa_kol_trend/allow_dept_list', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  getPaDataSummary(data) {
    return request.post('/pa_kol_trend/data_summary', data)
  },
  /**
   * @description 初始化【涨粉】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  initPaKolTrendQuarterData(data) {
    return request.post('/pa_kol_trend/init_data', data)
  },
  initPaKolTrendSetting(data) {
    return request.post('/pa_kol_trend/init_setting', data)
  },
  /**
   * 考核设置调整
   * @param data
   * @returns {Promise}
   */
  changePaKolTrendQuarterNeedCheck(data) {
    return request.post('/pa_kol_trend/change_need_check', data)
  },
  savePaKolTrendData(data) {
    return request.post('/pa_kol_trend/save_cell', data)
  },
  batchSetPaKolTrendData(data) {
    return request.post('/pa_kol_trend/batch_set_data', data)
  },
  getPaKolTrendBatchSetting(data) {
    return request.post('/pa_kol_trend/get_batch_setting', data)
  },
  exportPaKolTrendQuarterData(data) {
    return request.postBlob('/pa_kol_trend/export', data)
  },
  getPaKolTrendQuarterSettingList(data) {
    return request.post('/pa_kol_trend/get_setting_list', data)
  },
  getPaKolSetting(data) {
    return request.post('/pa_kol_setting/get_setting', data)
  },
  getPaKolTrendQuarterSetting(data) {
    return request.post('/pa_kol_trend/get_setting', data)
  },
  savePaKolTrendQuarterSetting(data) {
    return request.post('/pa_kol_trend/save_setting', data)
  },
  /**
   * @description 获取【营收】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  getPaKolRevenueQuarterData(data) {
    return request.post('/pa_kol_revenue/get_quarter_data', data)
  },

  /**
   * @description 初始化【营收】绩效目标一览季度数据
   * @param {*} data
   * @returns
   */
  initPaKolRevenueQuarterData(data) {
    return request.post('/pa_kol_revenue/init_data', data)
  },
  initPaKolRevenueSetting(data) {
    return request.post('/pa_kol_revenue/init_setting', data)
  },

  savePaKolRevenueData(data) {
    return request.post('/pa_kol_revenue/save_cell', data)
  },
  /**
   * 考核设置-营收
   * @param data
   * @returns {Promise}
   */
  changePaKolRevenueQuarterNeedCheck(data) {
    return request.post('/pa_kol_revenue/change_need_check', data)
  },
  batchSetPaKolRevenueData(data) {
    return request.post('/pa_kol_revenue/batch_set_data', data)
  },

  getPaKolRevenueBatchSetting(data) {
    return request.post('/pa_kol_revenue/get_batch_setting', data)
  },

  exportPaKolRevenueQuarterData(data) {
    return request.postBlob('/pa_kol_revenue/export', data)
  },
  getPaKolRevenueQuarterSetting(data) {
    return request.post('/pa_kol_revenue/get_setting', data)
  },
  getPaKolRevenueQuarterSettingList(data) {
    return request.post('/pa_kol_revenue/get_setting_list', data)
  },
  savePaKolRevenueQuarterSetting(data) {
    return request.post('/pa_kol_revenue/save_setting', data)
  },

  /**
   * 项目组绩效-涨粉数据
   * @param data
   * @returns {Promise}
   */
  getPaDeptTrendDeptData(data) {
    return request.post('/pa_group_trend/get_quarter_data', data)
  },
  /**
   * @description 发布数据
   * @param {*} data
   * @returns
   */
  publishPaDeptTrendDeptData(data) {
    return request.post('/pa_group_trend/publish', data)
  },
  /**
   * 初始化
   * @param data
   * @returns {Promise}
   */
  initPaGroupTrendDeptData(data) {
    return request.post('/pa_group_trend/init_data', data)
  },
  exportPaTrendRevenueQuarterData(data) {
    return request.postBlob('/pa_group_trend/export', data)
  },
  /**
   * 初始化
   * @param data
   * @returns {Promise}
   */
  initPaDeptRevenueDeptData(data) {
    return request.post('/pa_group_revenue/init_data', data)
  },
  exportPaGroupRevenueQuarterData(data) {
    return request.postBlob('/pa_group_revenue/export', data)
  },
  /**
   * @description 发布数据
   * @param {*} data
   * @returns
   */
  publishPaDeptRevenueDeptData(data) {
    return request.post('/pa_group_revenue/publish', data)
  },

  batchSetPaDeptTrendData(data) {
    return request.post('/pa_group_trend/batch_set_data', data)
  },


  /**
   * 保存【项目组绩效】单元格数据
   * @param data
   * @returns {Promise}
   */
  savePerformanceTrendDeptData(data) {
    return request.post('/pa_group_trend/save_cell', data)
  },
  /**
   * 保存【项目组绩效】单元格数据
   * @param data
   * @returns {Promise}
   */
  savePaRevenueDeptData(data) {
    return request.post('/pa_group_revenue/save_cell', data)
  },

  //pa_group_revenue
  getPaDeptRevenueDeptData(data) {
    return request.post('/pa_group_revenue/get_quarter_data', data)
  },
  batchSetPaDeptRevenueData(data) {
    return request.post('/pa_group_revenue/batch_set_data', data)
  },

  /**
   * 总监绩效-部门营收
   * @param data
   * @returns {Promise}
   */
  getPaChiefRevenueDeptData(data) {
    return request.post('/pa_chief_revenue/get_quarter_data', data)
  },
  //总监绩效-确认发布
  publishPaChiefRevenueDeptData(data) {
    return request.post('/pa_chief_revenue/publish', data)
  },
  /**
   * 总监绩效列表
   * @param data
   * @returns {Promise}
   */
  getPaChiefList(data) {
    return request.post('/pa_chief/list', data)
  },

  /**
   * 保存总监绩效
   * @param data
   * @returns {Promise}
   */
  savePaChief(data) {
    return request.post('/pa_chief/save', data)
  },
  // batchSetPaChiefTrendData(data) {
  //   return request.post('/pa_chief/batch_set_data', data)
  // },
  batchSetPaChiefRevenueData(data) {
    return request.post('/pa_chief_revenue/batch_set_data', data)
  },
  /**
   * 保存总监绩效
   * @param data
   * @returns {Promise}
   */
  getPaChief(data) {
    return request.post('/pa_chief/get_info', data)
  },
  /**
   * 核算完成状态切换
   * @param data
   * @returns {Promise}
   */
  checkPaChiefStatus(data) {
    return request.post('/pa_chief/check_status', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  getChiefBonusList(data) {
    return request.post('/pa_chief/list', data)
  },
  /**
   *
   * @param data
   * @returns {Promise}
   */
  exportChiefBonusList(data) {
    return request.postBlob('/pa_chief/export', data)
  },
  /**
   * 审批状态状态切换
   * @param data
   * @returns {Promise}
   */
  auditPaChiefStatus(data) {
    return request.post('/pa_chief/audit_status', data)
  },
  /**
   * 绩效考核MCN中心部门设置
   * @param data
   * @returns {Promise}
   */
  getPaDeptSettingList(data) {
    return request.post('/pa_dept_setting/list', data)
  },
  /**
   * 季度可选部门选项（下拉框数据）
   * @param data
   * @returns {Promise}
   */
  getPaDeptSettingOptions(data) {
    return request.post('/pa_dept_setting/dept_options', data)
  },
  getPaDeptSetting(data) {
    return request.post('/pa_dept_setting/get_setting', data)
  },
  /**
   * 部门关联的项目组红人清单
   * @param data
   * @returns {Promise}
   */
  loadPaDeptKolList(data) {
    return request.post('/pa_dept_setting/dept_kol_list', data)
  },
  savePaDeptSetting(data) {
    return request.post('/pa_dept_setting/save', data)
  },
  //初始化
  initPaDeptSetting(data) {
    return request.post('/paKpiDept/init_dept', data)
  },
  /**
   * 批量添加考核部门
   * @param data
   * @returns {Promise}
   */
  batchAddPaDeptSetting(data) {
    return request.post('/pa_dept_setting/batch_add', data)
  },
  /**
   * 同步上季度
   * @param data
   * @returns {Promise}
   */
  syncLastPaDeptSetting(data) {
    return request.post('/pa_dept_setting/sync_last', data)
  },
  /**
   * 初始化季度绩效目标
   * @param data
   * @returns {Promise}
   */
  initPaDeptData(data) {
    return request.post('/pa_dept_setting/init_dept_data', data)
  },

  editPaDeptSettingManager(data) {
    return request.post('/pa_dept_setting/edit_manager', data)
  },
  /**
   *
   * @param id
   * @returns {Promise}
   */
  deletePaDeptSetting(id) {
    return request.post('/pa_dept_setting/delete', { id: id })

  },
  /**
   * 获取部门下级部门列表
   * @param dept_id
   * @returns {Promise}
   */
  getNextDeptList(dept_id) {
    return request.post('/pa_dept_setting/next_dept_list', { dept_id: dept_id })
  },
  /**
   * 绩效考核的MCN中心
   * @param data
   * @returns {Promise}
   */
  getPaMcnList(data) {
    return request.post('/pa_dept_setting/get_mcn_list', data)
  },
  getPaDeptList(data) {
    return request.post('/pa_dept_setting/get_dept_list', data)
  },
  getPaDeptKolList(data) {
    return request.post('/pa_dept_setting/get_kol_list', data)
  },
  /**
   * 初始化总监绩效考核部门
   * @param data
   * @returns {Promise}
   */
  initPaChiefMcnQuarterData(data) {
    return request.post('/pa_dept_setting/init_setting', data)
  },
  initPaChiefRevenue(data) {
    return request.post('/pa_dept_setting/init_chief_revenue', data)
  },

  /**
   * @description 获取总监营收【营收绩效目标】
   * @param {*} data
   * @returns
   */
  getPaChiefRevenueQuarterData(data) {
    return request.post('/pa_chief_revenue/get_quarter_data', data)
  },
  exportPaChiefRevenueQuarterData(data) {
    return request.postBlob('/pa_chief_revenue/export', data)
  },
  /**
   * 初始化总监营收数据
   * @param data
   * @returns {Promise}
   */
  initPaChiefRevenueQuarterData(data) {
    return request.post('/pa_chief_revenue/init_data', data)
  },
  savePaChiefRevenueData(data) {
    return request.post('/pa_chief_revenue/save_cell', data)
  },
  /**
   * 追加部门总监的考核红人
   * @param data
   * @returns {Promise}
   */
  appendPaChiefSettingKol(data) {
    return request.post('/pa_dept_setting/append_kol', data)
  },
  /**
   * 移除部门考核红人
   * @param data
   * @returns {Promise}
   */
  deletePaChiefSettingKol(data) {
    return request.post('/pa_dept_setting/delete_kol', data)
  },
  /**
   * 初始化考核项
   * @param id
   * @returns {Promise}
   */
  initItemPaChiefRevenue(id) {
    return request.post('/pa_chief_revenue/init_item', { id: id })
  },
  /**
   * 获取项目组红人设置
   * @param data
   * @returns {Promise}
   */
  getPaGroupSettingList(data) {
    return request.post('/pa_group_setting/list', data)
  },
  /**
   * 指定时间段内，在约红人
   * @param data
   * @returns {Promise}
   */
  getPaGroupSettingKolRange(data) {
    return request.post('/pa_group_setting/kol_range', data)
  },
  /**
   * 同步红人分组数据（季度）
   * @param data
   * @returns {Promise}
   */
  syncPaGroupSetting(data) {
    return request.post('/pa_group_setting/sync', { year: data.year, quarter: data.quarter })
  },

  loadPaGroupOptions(data) {
    return request.post('/pa_group_setting/group_options', { year: data.year, quarter: data.quarter })
  },
  /**
   * 保存项目组红人设置
   * @param data
   * @returns {Promise}
   */
  savePaGroupSetting(data) {
    return request.post('/pa_group_setting/save', data)
  },
  /**
   *删除 项目组红人设置
   * @param data
   * @returns {Promise}
   */
  deletePaGroupSetting(data) {
    return request.post('/pa_group_setting/delete', data)
  },

  /**
   * 获取部门成员
   * @param dpt_id
   * @returns {Promise}
   */
  getDeptMembers(dpt_id) {
    return request.post('/pa_group_setting/dept_members', { dpt_id: dpt_id })
  },
  /**
   * 获取部门成员
   * @param group_id
   * @returns {Promise}
   */
  getGroupKolList(group_id) {
    return request.post('/artist/group_kol_list', { group_id: group_id })
  },

  /**
   * 【查询】项目组奖金分配列表
   * @description 获取【奖金】月数据(分组)
   * @param {*} data
   * @returns
   */
  getPaGroupBonusList(data) {
    return request.post('/pa_group_bonus/list', data)
  },
  /**
   * 项目组奖金分配详情
   * @param data
   * @returns {Promise}
   */
  getPaGroupBonusInfo(data) {
    return request.post('/pa_group_bonus/get_info', data)
  },
  /**
   * 保存奖金信息
   * @param data
   * @returns {Promise}
   */
  savePaGroupBonus(data) {
    return request.post('/pa_group_bonus/save_bill', data)
  },
  /**
   * 提交奖金分配明细 -》待总监审核
   * @param data
   * @returns {Promise}
   */
  commitPaGroupBonusBill(data) {
    return request.post('/pa_group_bonus/commit', data)
  },
  /**
   * 审核：人资审核和总监审核
   * @param data
   * @returns {Promise}
   */
  checkPaGroupBonusBill(data) {
    return request.post('/pa_group_bonus/check_bill', data)
  },

  revokePaGroupBonusBill(data) {
    return request.post('/pa_group_bonus/revoke_bill', data)
  },
  exportPaGroupBonusBill(data) {
    return request.postBlob('/pa_group_bonus/export', data)
  },
  /**
   * 添加奖金分配的成员
   * @param data
   * @returns {Promise}
   */
  addPaGroupBonusMembers(data) {
    //ym: null, group_id: '', group_name: '',members

    return request.post('/pa_group_bonus/add_members', {
      ym: data.ym,
      group_id: data.group_id,
      group_name: data.group_name,
      members: data.members
    })
  },
  /**
   * 删除奖金分配成员
   * @param id
   * @returns {Promise}
   */
  delPaGroupBonusMember(id) {
    let data = { id: id }
    return request.post('/pa_group_bonus/del_member', data)
  },

  /**
   * 显示或隐藏记录
   * @param data
   * @returns {Promise}
   */
  changePaGroupBonusShowStatus(data) {
    // let data = {id: id}
    return request.post('/pa_group_bonus/change_show', data)
  },

  /**
   * 考核设置修改
   * @param data
   * @returns {Promise}
   */
 changePaKolSetting(data) {

    return request.post('/pa_kol_setting/change', data)
  },
  getPaDeptGmvDeptData(data) {
    return request.post('/pa_group_gmv/get_quarter_data', data)
  },
  /**
   * 保存【项目组绩效】单元格数据
   * @param data
   * @returns {Promise}
   */
  savePaDeptGmvDeptData(data) {
    return request.post('/pa_group_gmv/save_cell', data)
  },

  exportPaGroupGMVQuarterData(data) {
    return request.postBlob('/pa_group_gmv/export', data)
  },

  /**
   * @description 发布数据
   * @param {*} data
   * @returns
   */
  publishPaDeptGMVDeptData(data) {
    return request.post('/pa_group_gmv/publish', data)
  },
}

